@import "_variables.scss";

.frame {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $color-surface-blue-light;
  border-radius: $border-radius-large;
  padding: var(--chakra-space-12-8);
  width: 100%;
  box-shadow: $shadow-box-default;
}

.box-group {
  padding-left: $padding-large;
}

.requestclassroom-title {
  padding-bottom: var(--chakra-space-9-6);
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-l;
  line-height: $line-height-xlarge;
}

.requestclassroom-subtext {
  font-weight: $font-weight-regular;
  font-size: $font-size-14px;
  line-height: $line-height-small;
}

.FAST-secondary-button {
  justify-content: center;
  padding: $padding-small;
  font-size: $font-size-reg;
  font-weight: $font-weight-bold;
}

.requestclassroom-submit-btn {
  text-transform: none !important;
}
