@import "_variables.scss";

.banner-slider-container {
  display: flex;
  justify-content: left;
  align-items: center;
  background: linear-gradient(
    180deg,
    $color-font-card -96.91%,
    $color-default-font-color 94.1%
  );
  min-height: 360px;
  width: 100%;
  color: $color-default-white;
  position: relative;
  padding: var(--chakra-space-16) var(--chakra-space-16) var(--chakra-space-16)
    var(--chakra-space-25-6);

  &:before {
    content: "";
    opacity: 0.3;
    background: url("../../../public/assets/images/dot-pattern.svg")
      no-repeat;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    background-size: cover;
    background-position: 100%;
  }
  .banner-text {
    width: 65%;
    margin-right: var(--chakra-space-11);
    h2 {
      font-size: $font-size-xl;
      line-height: $line-height-xxlarge;
      font-weight: var(--chakra-fontWeights-semibold);
    }
    p {
      font-size: $font-size-med;
      line-height: $line-height-medium;
      font-weight: var(--chakra-fontWeights-normal);
      padding: var(--chakra-space-8) 0;
    }
    .banner-buttons {
      display: flex;
      gap: var(--chakra-space-12-8);
      align-items: flex-start;

      button {
        text-transform: none;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-mobile-desktop) {
  .banner-slider-container {
    min-height: unset;
    height: auto;
    align-items: flex-start;
    padding: var(--chakra-space-12-8) var(--chakra-space-9-6)
      var(--chakra-space-9-6) var(--chakra-space-9-6);

    .banner-text {
      width: 100%;
      margin: 0;
    }
  }
}
